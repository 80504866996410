import axios from "axios";
import { number, z } from "zod";
import {
  dateableString,
  getAuthorizedConfig,
  numberablePositiveString,
} from "../../helpers/helpers";
import {
  ActivityPlatformStatusEnum,
  ActivityPricesStatusEnum,
  ActivityPricesTypeEnum,
  ActivityTypeEnum,
  ActivityTypeHours,
  SpecialSlotTypeEnum,
} from "helpers/constants";
import { discount } from "./discounts";
import { minimalTimeslotValue } from "components/activities/formCards/hoursForm/HoursForm.constants";

const slot = z.object({
  to: z.string().min(5),
  from: z.string().min(5),
});

const day = z.object({
  slots: z.array(slot),
});

export const activityIndividualBookingSettings = z.object({
  type: z.literal(ActivityTypeEnum.INDIVIDUAL),
  maxCapacity: z.number().min(1),
});

export const activityGroupBookingSettings = z.object({
  type: z.literal(ActivityTypeEnum.GROUP),
  maxUnitsPerGroup: z.number().min(1),
  maxGroups: z.number().min(1),
});

export const activityBookingSettings = z.union([
  activityIndividualBookingSettings,
  activityGroupBookingSettings,
]);

export type ActivityBookingSettings = z.infer<typeof activityBookingSettings>;

const timezone = z.object({
  dstOffset: z.number().optional(),
  rawOffset: z.number().optional(),
  timeZoneId: z.string().optional(),
  timeZoneName: z.string().optional(),
});

const imageSchema = z.object({
  url: z.string(),
  title: z.string().optional(),
  base64File: z.string().optional(),
  mimeType: z.string().optional(),
});

export const activityInformations = z.object({
  name: z.string(),
  category: z.string(),
  description: z.string(),
  website: z.string().optional().nullable(),
  images: z.array(imageSchema).min(1, { message: "pictureRequired" }),
  slotDuration: z.number().min(0).max(1439),
  address: z.string(),
  addressDetails: z.string().optional().nullable(),
  timezone: timezone.optional(),
  phone: z.string(),
  email: z.string().email(),
  cancelation: z.string(),
  isEnabled: z.boolean(),
  isDeleted: z.boolean().optional(),
  languages: z.string().array().optional().nullable(),
  bookingSettings: z.union([
    activityIndividualBookingSettings,
    activityGroupBookingSettings,
  ]),
});

export type ActivityInformations = z.infer<typeof activityInformations>;

const typeHoursEnum = z.enum([
  ActivityTypeHours.HOURS_OPERATIONS,
  ActivityTypeHours.TIME_SLOTS,
]);
const hoursSchema = z.object({
  "0": day,
  "1": day,
  "2": day,
  "3": day,
  "4": day,
  "5": day,
  "6": day,
});

export const activityHoursGeneral = {
  dates: z
    .object({
      from: dateableString,
      to: dateableString,
    })
    .nullable()
    .optional(),
  hours: hoursSchema,
};

export type ActivityHoursType = z.infer<typeof hoursSchema>;

export const activityHours = z.discriminatedUnion("typeHours", [
  z.object({
    typeHours: typeHoursEnum.extract([ActivityTypeHours.HOURS_OPERATIONS]),
    slotDuration: z.number().min(0).max(1439),
    ...activityHoursGeneral,
  }),
  z.object({
    typeHours: typeHoursEnum.extract([ActivityTypeHours.TIME_SLOTS]),
    slotDuration: z.number().min(minimalTimeslotValue).max(1439),
    ...activityHoursGeneral,
  }),
]);

export type Slot = z.infer<typeof slot>;
export type Day = z.infer<typeof day>;
export type ActivityHours = z.infer<typeof activityHours>;

const activityPrice = z.object({
  id: z.number().optional(),
  name: z.string().min(5),
  price: z.number().min(0),
  description: z.string().nullable(),
  status: z.nativeEnum(ActivityPricesStatusEnum),
  type: z.nativeEnum(ActivityPricesTypeEnum).nullable().optional(),
});

export type ActivityPrice = z.infer<typeof activityPrice>;

export const activityPrices = z.object({
  prices: z.array(activityPrice),
});

export type ActivityPrices = z.infer<typeof activityPrices>;

const activityChannel = z.object({
  platformId: z.number(),
  name: z.string(),
  status: z.nativeEnum(ActivityPlatformStatusEnum),
  activityExternalReference: z.string().optional().nullable(),
});

export const activityChannels = z.array(activityChannel);

export type ActivityChannels = z.infer<typeof activityChannels>;

const activity = z.object({
  id: z.number(),
  informations: activityInformations,
  hours: activityHours,
  prices: z.array(activityPrice),
  channels: z.array(activityChannel),
  outOfStock: z.boolean().optional(),
  discounts: z.array(discount).optional(),
});
export type Activity = z.infer<typeof activity>;

const upsertActivityInput = z.object({
  body: z.object({
    activityId: numberablePositiveString.nullable(),
    informations: activityInformations.optional(),
    bookingSettings: activityBookingSettings.optional(),
    hours: activityHours.optional(),
    prices: z.array(activityPrice).optional(),
    channels: z.array(activityChannel).optional(),
  }),
});

type UpsertActivityInput = z.infer<typeof upsertActivityInput>;

const availability = z.object({
  startTime: z.string(),
  endTime: z.string(),
  numberOfUnitBooked: z.number(),
  maxCapacity: z.number(),
  maxUnitsPerGroup: z.number(),
  specialSlotType: z.nativeEnum(SpecialSlotTypeEnum).optional(),
});

const getActivityAvailaibilitiesOutput = z.array(
  z.object({
    activityId: z.number(),
    date: z.string(),
    slotDuration: z.number().min(0).max(1439),
    availabilities: z.array(availability),
  }),
);

export type Availability = z.infer<typeof availability>;

export type ActivityAvailabilities = z.infer<
  typeof getActivityAvailaibilitiesOutput
>;

// get activities
export async function getActivities(
  displayDisabled?: boolean,
  isWidget?: boolean,
): Promise<Activity[]> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/activities`;
  config["params"] = {
    displayDisabled,
    isWidget
  };
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// get activity
export async function getActivityById(activityId: number): Promise<Activity> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/activities/activity/${activityId}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

// post/put activity
export async function upsertActivity(data: UpsertActivityInput["body"]) {
  let config: any = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/activities/`;
  config["data"] = data;
  const response = await axios.request({
    ...config,
    maxContentLength: 100000000,
    maxBodyLength: 1000000000,
  });
  return response;
}

// getAvailabilities
export async function getAvailabilities(
  activityId: number,
  from: string,
  to: string,
): Promise<ActivityAvailabilities> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/activities/availabilities/${activityId}?from=${from}&to=${to}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

const getAllActivitiesAvailability = z.object({
  start: z.string(),
  end: z.string(),
  resourceId: z.string(),
  data: z.object({
    activityId: z.number(),
    title: z.string(),
    category: z.string(),
    maxCapacity: z.number(),
    numberOfUnitBooked: z.number(),
    specialSlotType: z
      .enum([SpecialSlotTypeEnum.MARK_AS_FULL, SpecialSlotTypeEnum.UNAVAILABLE])
      .optional(),
  }),
});

export const getAllActivitiesAvailabilitiesOutput = z.array(
  getAllActivitiesAvailability,
);
export type GetActivitiesAvailabilitiesOutput = z.infer<
  typeof getAllActivitiesAvailabilitiesOutput
>;

// getAllActivitiesAvailabilities
export async function getAllActivitiesAvailabilities(
  from: string,
  to: string,
): Promise<GetActivitiesAvailabilitiesOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] =
    `${process.env.REACT_APP_API_URL}/activities/availabilities?from=${from}&to=${to}`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export const getAllClientActivitiesNameAndIdsOutput = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);

export type GetAllClientActivitiesNameAndIdsOutput = z.infer<
  typeof getAllClientActivitiesNameAndIdsOutput
>;

export async function getAllClientActivitiesNameAndIds(): Promise<GetAllClientActivitiesNameAndIdsOutput> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/activities/user-activities`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}